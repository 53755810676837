import "../../components/base.css";
import Layout from "../../components/layout";
import React from "react";


export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <br></br>
          <h1>&gt; HOW TOs</h1>
        </div>
        <br></br>
        <div>
          <h2>Eventually there will be stuff here /</h2>
            <h3>But not yet...</h3>
          <br></br>
        </div>
      </Layout>
    );
  }
}